@use "../_vars.scss" as *;

@media screen and (min-width: 328px) {

.project-dropper {
    margin-left: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    max-width: 100vw;
    //height: 100%;
    transition-property: color, background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    padding-bottom: 0rem;
    
    font-size: min(0.75rem, 2vw);

    &__wrapper{
        display: flex;
        flex-direction: row;
        
        &:hover{
            color: $OffWhite;
            
        }
    }

    &__title{
        cursor: pointer;
        width: 80vw;
        word-wrap: none;
        font-size: min(1.25rem, 2vw);
    }
}

@keyframes dropdown {
    0%{transform:translateX(-70%); }
    99%{transform:translateX(0); }
    100%{transform:translateX(0); }
}

.dropper-project {
    scroll-snap-align: none;
    margin-top: 0;
    height: 30%;
} 

}

@media screen and (min-width: 768px) {
    
    .project-dropper{

        //margin-top: -2rem;
        &__title{
            font-size: min(1rem,1.2vw);
        }
        
    }
}