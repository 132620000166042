@use "../_vars.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap');

@media screen and (min-width: 320px) {

    @keyframes fadeFolio {
        0%{
            opacity: 0;
        }
        75%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .folio-post {
        // position: absolute;
        // top:0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        
        // margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .folio-post-container{

        overflow: hidden;

        width:100%;
        height:100%;

        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        //animation: 10s fadeFolio forwards;
        background: $OffWhite;
    }

    .folio_stack{
        max-width: 100%;
        max-height: 50vh;
    }

    .folio_img{
        width: 100%;
        height: 100%;
    }

    .folio_vid{
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width: 768px) {

    
    .folio-post-container{
        align-self: center;
        flex-direction: row;    
        max-height: 80%;
    }
    
    .folio-post {
        height: 100%;
        width: auto;
    }

    .folio_img{
        width: auto ;
    }

    .folio_stack{
        max-width:50%;
        height: 70%;
    }
 
    
}

@media screen and (min-width: 1024px) {

    .folio-post-container{
        width: 50%;
    }

    .folio_stack{
        max-height: 70%;
        max-width: 50vw;
    }

    .folio_img{
        height: 80%;
        width: auto;
    }

    .folio_vid{
        width: 100%;
    }
    
}