@use "../_vars.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap');

@media screen and (min-width: 320px) {

    @keyframes slideInLeft {
        0%{position: absolute; right: -30vw;}
        100%{position: absolute; right: 0;}
    }

.info {
    position: relative;
    margin-top: 4rem;
    padding-right: 4rem;
    padding-left: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55vw;
    top:10%;
    height: 100%;
    //align-items: center;
    font-size: min(1.5rem,3vw);
    font-weight: bold;
    color: $OffWhite;
    left: 0vw;
    animation-delay: 2s;
    z-index: 999;

    &-links{
        height: 10rem;
        display: flex;
        flex-direction: column;
        //justify-content: flex-end;
        // right: 20%;
        // text-align: right;
    }

    &__descr {
        text-align: left;
        font-size: min(1.5rem,3vw);        
        color: $OffWhite;           
    }

    &__serv{
        font-size: 2.75vw;
        padding: none;
        margin: 0;
        opacity: 70%;
    }

    &__link{
        padding-top: 2rem;
        color: $OffWhite;
        text-decoration: none;
        &-snap{
            // position: absolute;
            // left:200%;
            &:visited {
            color: $OffWhite;
        }
        }
        
        &:visited {
            color: $OffWhite;
        }
        
        &:hover{
            color: $OffWhite;
        }
    }
}

}

@media screen and (min-width: 768px) {

    .info{

        &-links{
            //position:initial;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
           // height: 10vh;
        }

        &__desc{
            font-size: 1rem;
        }

    }
}

@media screen and (min-width: 1024px) {

    .info{

        &__descr{
            font-size: 1rem;
        }

        &__serv{
        font-size: 1rem;
    }
    }
}

@media screen and (min-width: 1920px) {
    .info{

        &__descr{
            font-size: 2rem;
        }

        &__serv{
            font-size: 2rem;
        }
    }
}