@use "../_vars.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap');


@media screen and (min-width: 320px) {

    body{
        background: $OffWhite;
        //scroll-snap-type: y mandatory;
        overflow: hidden;
    }

    html {
        //scroll-snap-type: y mandatory;
    }

.active{
    animation: fadeIn 0.5s ease;
    font-family: "Poppins";
    font-style: italic;
    font-size: min(1.5rem, 4vw);
    pointer-events: all;
}

.inactive{
    font-size: min(2rem, 6vw);
    pointer-events: all;
    font-family: "Poppins";
}

.centre-buffer{
    height: 30vh;
}
.home{
    background: $OffWhite;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;

    &:hover{
        
    }

    @keyframes fadeIn {
        0%{
            opacity: 0;
        }
        10%{
            opacity:1;
        }
        75%{
            opacity: 1;
        }
        100% {
            opacity: 0;
        }      
    }
    
    &__splash{
        
        //background-image: url("../../Assets/gif-bg.gif");
        background-size: cover;
        z-index: -99;
        color: $Navy;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 0.1rem;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: right;
        justify-content: right;
        text-align: center;
        opacity: 1;
        pointer-events: all;
        scroll-snap-align:  start;
        pointer-events: all;
        

        &-content{
            width: 80vw;
            height:80vh;
            animation: fadeIn 0.6s;
            pointer-events: all;
            
            cursor: pointer;
        }

        &__projects-button {
            position: relative;
            max-width: 70vw;
            max-height: 20vh;
            padding: none;
            opacity: 0;
            transform: skewX(10deg);
            animation: fadeIn 2s ease forwards;
            animation-delay: 1s;
            transition-property: color, border, background-color, transform;
            transition-duration: 0.6s;
            transition-timing-function: ease;

            &:before{
                content:"";
                position: absolute;
                top: -2px;
                left: -1px;
                right: -1px;
                bottom: -3px;
                border: 0.2rem solid $OffWhite;
                transition-property: top, left, right, bottom;
                transition-duration: 0.6s;
                transition-timing-function: ease;
            }

            &:after{
                content:"";
                position: absolute;
                top: -2px;
                left: -1px;
                right: -1px;
                bottom: -3px;
                border: 0.2rem solid $OffWhite;
                transition-property: top, left, right, bottom;
                transition-duration: 0.6s;
                transition-timing-function: ease;
            }
            

        &:hover {
            transform: skew(-10deg);
            border: 0.2rem solid $OffWhite;
            color: white;
            background-color: $OffWhite;
            &:before{
                top: -1rem;
                left: -1rem;
                right: -1rem;
                bottom: -1rem;
            }
            &:after{
                top: -2rem;
                left: -2rem;
                right: -2rem;
                bottom: -2rem;
            }
        }

        }

        &__bg-vid {
            position: fixed;
            z-index: -110;
            width: 100vw;
            height:auto;
        }

    
    }

    &__post-container{
        position: absolute;
        top: 0%;
        animation: fadeIn 10s ease forwards;
        background: $OffWhite;  
        width: 100%;
        height: 100vh;
    }

    &__post{
        position: relative;
        left: 40%;
    }
    
    }
    .shuffle-text {
        font-size: 2rem;
        text-align: center;
        font-family: "Poppins";
        font-style: italic;
        color: $OffWhite;
        ///color: #6d0000;
        &-char{
            animation: none;
        }
    }

    .info {

        &__desc {
            position: relative;
            font-size: min(1.25rem,2vw);
            max-height: 10vh;
            max-width: 70vw;
            color: $Navy;

            &_p {
                position: absolute;
                bottom:0;
                left:-45vw;
                font-size: min(1.25rem,2vw);
            }

            &_l{
                position: absolute;
                bottom:0;
                right:-45vw;
            }
        }
    }

}

@media screen and (min-width: 768px) {

    .shuffle-text {
        font-size: 4rem;
    }

    .home{

        &__splash{
            &__projects-button {
                padding: 1rem;
            }
        }
        
    }

}

@media screen and (min-width: 1024px) {

    .home{
        &__splash {
            &-content{
                width: 70vw;
            }
        }
        
    }
}