@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html{
  height: 100%;
}

body{
  height: 100%;
}

#root{
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif, cursive !important;
}

.site{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #0c1014;
}



::-webkit-scrollbar {
  width: 10px;
  border-radius: 50%;
  border: 19x solid #0c1014;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0c1014;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c1014;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0c1014;
}

a{
  text-decoration: none;
}
a:visited{
    color: #0c1014;
    mix-blend-mode: difference;
    text-decoration: none;
  }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
